/* eslint-disable max-len */
import React, { useContext } from 'react';
import { string } from 'prop-types';
import { Location } from '@one-thd/sui-icons';
import { Typography } from '@one-thd/sui-atomic-components';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { useDataModel, extend } from '@thd-nucleus/data-sources';
import { AisleBayPodsContext } from './AisleBayPodsContext';
import { dataModel } from './dataModel';

export const AisleBayPod = ({ itemId, storeId }) => {
  const { getAisleBayForPod, showAisleBayPods } = useContext(AisleBayPodsContext);

  const { data, error, loading } = useDataModel('product', {
    variables: {
      itemId,
      storeId
    },
    ssr: false
  });

  if (!data && loading) return null;
  if (error) return null;
  const { product } = data || {};
  const { storeSkuNumber } = product?.identifiers || {};
  const { getSellableQuantityYourStore } = podFulFillmentUtils;
  const isSellableAtSelectedStore = getSellableQuantityYourStore(product) > 0;

  if (!(showAisleBayPods && storeId && storeSkuNumber && isSellableAtSelectedStore)) {
    return null;
  }

  const aisleBaySku = getAisleBayForPod({ storeIdNum: storeId, storeSku: storeSkuNumber });

  if (!aisleBaySku) {
    return null;
  }

  const { aisle, bay, invLocDesc } = aisleBaySku;

  const showAisle = !!aisle;
  const showDescriptionNotAisle = !!invLocDesc && !aisle;
  const showBay = !!bay && (!!invLocDesc || showAisle);

  if (!(showAisle || showDescriptionNotAisle)) {
    return null;
  }

  return (
    <div className="sui-flex" data-component="AisleBayPod">
      <div className="sui-flex sui-items-center sui-mr-2">
        <Location size="small" />
      </div>
      <div>
        <Typography variant="body-sm">
          {showAisle && `Aisle ${Number(aisle) || aisle}`}
          {showDescriptionNotAisle && `${invLocDesc.toString().split(',')[0]}`}
          {showBay && ` | Bay ${Number(bay) || bay}`}
        </Typography>
      </div>
    </div>
  );
};

AisleBayPod.displayName = 'AisleBayPod';

AisleBayPod.propTypes = {
  itemId: string,
  storeId: string
};

AisleBayPod.defaultProps = {
  itemId: '',
  storeId: ''
};

AisleBayPod.dataModel = extend({}, dataModel);